<template>
  <v-row
    class="px-10"
    dense
  >
    <v-col
      cols="12"
      md="12"
    >
      <ContactsCard
        v-for="(contact, idx) in client.client_contact"
        :key="idx"
        :contact="contact"
        :contact_idx="idx"
        @remove="removeContact"
        @changeStatus="changeStatus"
      />
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-btn
        @click="addContact"
        class="primary"
        icon
        dark
      >
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
  },
  components: {
    ContactsCard: () => import('./ContactsCard'),
  },
  methods: {
    addContact() {
      this.client.client_contact.push({})
    },
    removeContact(contact) {
      this.$store.commit('setModal', {
            show: true,
            component: 'warning',
            text: 'Tem certeza que deseja deletar este contato?',
            caption: 'Esta ação não poderá ser desfeita',
            confirm: () => {
              this.$api
                .delete(`client_contacts/delete/${contact.id}`)
                .then(() => {
                  this.$store.commit('setModal', {
                    show: true,
                    component: 'success',
                    text: 'Contato deletado com sucesso!',
                    confirm: () => {
                      this.$store.commit('setModal', {
                        show: false,
                      })
                    },
                  })                
                  let idx = this.client.client_contact.indexOf(contact)
                  this.client.client_contact.splice(idx, 1)
                })
                .catch((err) => {
                  this.$store.commit('setModal', {
                    show: true,
                    component: 'error',
                    text: 'Falha ao tentar deletar o contato',
                    caption: err.response.data.message,
                    confirm: () => {
                      this.$store.commit('setModal', {
                        show: false,
                      })
                    },
                  })
                })
            },
            negate: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            }
          })
    },
    changeStatus(contact) {
      this.$api
        .patch('client_contacts/status', {
          id: contact.id,
          status: !contact.status,
        })
        .then(() => {
          contact.status = !contact.status
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Status do contato atualizado com sucesso!',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .catch((err) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Falha ao tentar atualizar o status do contato',
            caption: err.response.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
}
</script>